export const appendBasePath = (basePath: string, link: string): string | null => {
    console.log(link)
    //TODO: refactor to make more maintainable with designer link
    if (link === '/designer-store') {
        return link;
    }
    
    if (!link.includes('/men') && !link.includes('/women')) {
        return `${basePath}${link}`;
    } else {
        return link;
    }
};