import React, { useContext, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import { ProductList } from '../';
import { Carousel, IconsBlock } from '../../core';
import { setRecentlyViewedItem, getRecentPdp } from '../../../helpers/';

import styles from './styles.module.css';
import { CartContext } from '../../../context/CartContext';
import { DrawerContext } from '../../../context/DrawerContext';
import SizeGuide from '../../storyblok/nested/Drawer/SizeGuide';
import { SizeGuideStoryblok } from '../../../component-types-sb';
import { WishlistContext } from '../../../context/WishlistContext';
import { ProductService } from '../../../modules/product.service';
import { Tracking } from '../../../utils/tracking';
import Breadcrumbs from '../../core/Breadcrumbs';
import { getStorageValue } from '../../../utils/sessionStorage';
import RelatedProducts from '../RelatedProducts';
import { socialMediaPlatforms } from '../../core/SocialShareButton/utils';
import { SocialShareButton } from '../../core/SocialShareButton';
import Link from 'next/link';
import { ItemWatchListToggleWithCount } from './ItemWatchListToggleWithCount';
import { WatchlistButton } from './WatchlistButton';
import { toPDPtitle } from './utils/helpers';
import { defaultPDPIcons, designerPDPIcons } from './utils/pdpIcons';
import pluralize from 'pluralize';
import { ItemSpecifics } from './ItemSpecifics';
import { ItemPrice } from './ItemPrice';
import { CanonicalItem } from '../../../data-fetching/canonicalItem';
import { ProductMini } from '../../../helpers/recentlyviewed.utility';
import { ToggleCartItemButton } from './ToggleCartItemButton';
import { algoliaInsights } from '../../searching/insights';
import { algoliaIndices } from '../../../utils/constants';
import { useCookieConsent } from '../CookieConsent/useCookieConsent';
import { useItemCallouts } from '../../searching/useItemCallouts';
import { ChevronDownIcon, ChevronUpIcon } from '@radix-ui/react-icons';

interface Props {
  product: ProductService.Single;
  item: CanonicalItem;
  sizeGuide?: SizeGuideStoryblok;
  isAlgoliaProduct?: boolean;
}

export const ProductSingle: React.FC<Props> = ({ product, item, sizeGuide, isAlgoliaProduct }) => {
  const [recentlyViewed, setRecentlyViewed] = useState<ProductMini[] | null>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const { addItem } = useContext(CartContext);
  const { showDrawer } = useContext(DrawerContext);
  const { toggleItemInWishlist } = useContext(WishlistContext);
  const [cookieConsent] = useCookieConsent();
  const [showDefects, setShowDefects] = useState<boolean>(false);

  const sendViewEvent = useRef<boolean>();
  const [prevUrl, setPrevUrl] = useState<string>('');
  const [hit, setHit] = useState({ queryId: '', objectId: '' });
  const currentURL = typeof window !== 'undefined' ? window.location.href : '';

  const isOutOfStock = !item?.in_stock;

  async function handleAddToCart(variantId: string | number) {
    setLoading(true);
    Tracking.AddToCart(product);

    try {
      if (hit.queryId.length > 0 && cookieConsent?.analytics_storage) {
        algoliaInsights('addedToCartObjectIDsAfterSearch', {
          //Triggers algolia insights conversion event
          index: algoliaIndices.shopifyProducts,
          eventName: 'Product Added to Cart',
          queryID: hit.queryId,
          objectIDs: [hit.objectId],
          currency: "GBP",
          value: item.price,
          objectData:[{
            price: item.price,
            discount: item.strikethrough_price ? item.strikethrough_price - item.price : undefined,
            quantity: 1,
          }]
        });
      }
    } catch (err) {
      console.log(err);
    }

    await addItem({ variantId:`${variantId}`, quantity: 1 }, true);
    setLoading(false);
  }

  async function getRecent() {
    const recent = await getRecentPdp();
    setRecentlyViewed(recent);
  }

  const getPrevious = () => {
    const current = getStorageValue('productsPrev');
    const id = item?.id;
    return current?.[id];
  };

  const getHitIds = () => {
    const current = getStorageValue('productHitIds');
    const id = item?.id;
    return current?.[id];
  };

  const handleShowSizeGuide = () => showDrawer(SizeGuide as any, { alignment: 'right', sizeGuide: sizeGuide });

  useEffect(() => {
    if (product) {
      setRecentlyViewedItem(product);

      if (!sendViewEvent.current && typeof window !== 'undefined') {
        sendViewEvent.current = true;
        Tracking.ViewProduct(product);
      }
    }
    getRecent();
    const previous = getPrevious();
    const hitIds = getHitIds();

    setPrevUrl(previous);
    setHit(hitIds);
  }, [product]);

  const toggleShowDefects = () => {
    setShowDefects(prevState => !prevState);
  };

  const promoCallouts = useItemCallouts(item);
  const callout = promoCallouts.find(() => true); // first one


  if (!item) return null; // 404
  const department = item.department.replace(/'s$/, '').toLowerCase();
  const productStyleURL = `https://thrift.plus/${department}/search?q=${item.style}`;
  const productBrandURL = item.brand ? `https://thrift.plus/${department}/search?q=${encodeURIComponent(item.brand)}` : null;

  return (
    <section>
      {/* Product Info */}
      <div className="Container mb-8">
        <Breadcrumbs prevUrl={prevUrl} item={item} />
        <div className={styles.product}>
          <div className={styles.ImageContainer}>
            <div className="relative flex">
              <Carousel key={`carousel-${item.id}`} imageList={item.photos} />
              {callout && (
                <div className={`${callout.colour === 'blackAlt' ? 'text-white' : 'text-blackAlt'} bg-${callout.colour} left-0 absolute px-2 py-1 body-12-bold-caps top-5`}>
                  {callout.label}
                </div>
              )}
                <ItemWatchListToggleWithCount productId={item.id} onClick={() => toggleItemInWishlist(item)} />
            </div>
             <div className="flex flex-wrap items-center justify-between pt-2 gap-2">
                <a className="body-14-underline" target='_blank' rel='nofollow noreferrer'
                href={`https://docs.google.com/forms/d/e/1FAIpQLSdv_h3RhaANNz6k4gc7WtkqcGK_SrtRlHjJKzNhvj95KgzinQ/viewform?entry.150165209=${item?.sku}`}>
                  Give us feedback on this listing
                </a>
               <span className="body-12 text-gray-500">{item.sku}</span>
                <div className="flex items-center gap-2 w-21">
                  {socialMediaPlatforms.map((platform) => (
                    <SocialShareButton
                      key={platform.name}
                      url={currentURL}
                      title={`Check out this Thrift+ find`}
                      iconUrl={platform.url}
                      shareButton={platform.shareButton}
                    />
                  ))}
                </div>
              </div>
          </div>

          <div className={styles.MainContainer}>
            <div className="flex flex-row justify-between">
              <div className="flex flex-col justify-between">
                <h1 className={`body-16-bold md:body-20-bold ${styles.title}`}>
                    {toPDPtitle(item)}
                </h1>
                {item?.size && (
                  <p className={styles.description}>{`${item?.size.display}`}</p>
                )}

                <ItemPrice price={item.price} strikethrough_price={item.strikethrough_price} />
              </div>
              <div className="flex items-end justify-end">
                {sizeGuide && (
                  <button className="body-14-underline" onClick={handleShowSizeGuide}>
                    Size guide
                  </button>
                )}
              </div>
            </div>

            <form>
              <div className={styles.buttonContainer}>
                <ToggleCartItemButton item={item} isLoading={isLoading} handleAddToCart={handleAddToCart}/>  
                {!isOutOfStock && (
                  <div className="block grow">
                    <WatchlistButton item={item} />
                </div>
                )}
              </div>
            </form>

            <IconsBlock icons={item.brand_type === 'designer' ? designerPDPIcons : defaultPDPIcons} />

            <div className={classNames(styles.block, 'rich-text')}>
              <div className="flex items-center justify-between">
                <h3 className={classNames(styles.smallTitle, 'body-16-bold')}>Details</h3>
              </div>
              {item.style && (
                <div style={{ marginBottom: '5px' }}>
                  <b>
                    <a href={productStyleURL}>
                      {pluralize(item.style)}
                    </a>
                  </b>
                    {item.brand && productBrandURL && 
                      <>
                      {' '}
                        <span className="lowercase">by </span>
                          <b>
                            <a href={productBrandURL}>
                              {item?.brand}
                            </a>
                        </b>
                      </>
                    }
                </div>
              )}
              
              <ItemSpecifics item={item} />
            </div>
            
            {item?.seller && (
              <div className="mb-8"><Link className={classNames('body-14-bold-underline')} href={`/${department}/search?q=seller-${item?.seller}`}>
                Shop more from this seller
              </Link></div>
            )}

            {item.defects.length > 0 && (
              <>
                <button onClick={toggleShowDefects} className="flex items-center">
                  <span className="flex">
                    {showDefects ? <ChevronUpIcon width={20} height={20} /> : <ChevronDownIcon width={20} height={20} />}
                    <h3 className={classNames(styles.smallTitle, "body-14-bold ml-1 hover:underline")}>Defects</h3>
                  </span>
                </button>
                {showDefects && <ul className="pl-5 list-disc">
                  {item.defects.map((defect: string) => (
                    <li key={defect}>{defect}</li>
                  ))}
                </ul>}
              </>
            )}
        </div>
        </div>
      </div>

      {/* Related Products */}
      <RelatedProducts variantId={item.variant_id} />

      {recentlyViewed && (
        <div className="Container">
          <div className={styles.DetailsContainer}>
            <div className={styles.block}>
              <h3 className={classNames(styles.largeTitle, 'body-18-bold')}>Recently Viewed</h3>
              <ProductList type="recently-viewed" items={recentlyViewed} />
            </div>
          </div>
        </div>
      )}
    </section>
  );
};
