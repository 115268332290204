import React, { useContext } from 'react';
import { CanonicalItem } from '../../../../../../data-fetching/canonicalItem';
import { Button } from '../../../../../core';
import { WishlistContext } from '../../../../../../context/WishlistContext';
import { CartContext } from '../../../../../../context/CartContext';

export const MoveToCartButton: React.FC<{item: CanonicalItem}> = ({item}) => {
    const {toggleItemInWishlist} = useContext(WishlistContext);
    const { addItem } = useContext(CartContext);

    const isAvailable = item.in_stock;

    return (
        <Button
          className="w-1/2"
          onClick={() => {
            toggleItemInWishlist(item);

            addItem({
              quantity: 1,
              variantId: `${item.variant_id}`,
            });
          }}
          text={isAvailable ? 'move to bag' : 'Sold out'}
          disabled={!isAvailable}
        />
    )
}