import { createContext, useState, useEffect, useCallback, useContext, useRef } from 'react';
import { CartService } from '../modules/cart.service';
import { CheckoutLineItemInput, CheckoutLineItemUpdateInput } from '../modules/shopify.service';
import { DrawerContext } from './DrawerContext';
import Cart from '../components/storyblok/nested/Drawer/Cart';
import { Tracking } from '../utils/tracking';
import { thriftBagVariantIDs } from '../utils/constants';
import { MAX_BAGS_PER_ORDER } from '../consts/bags';

export interface CartContextType {
  cart: CartService.Cart;
  addItem: (lineItem: CheckoutLineItemInput, showTooltip?: boolean) => Promise<void>;
  addThriftBag: (lineItem: CheckoutLineItemInput, showTooltip?: boolean) => Promise<void | Error>;
  updateItem: (lineItem: CheckoutLineItemUpdateInput) => Promise<void>;
  removeItem: (lineItemId: string) => Promise<void>;
  clearCart: () => Promise<void>;
  openCart: () => void;
  atMaxThriftBags: () => boolean,
  showThriftBagUpsell: boolean;
  showCartHeaderTooltip: boolean;
}

const CartContext = createContext<CartContextType>(undefined as any); // Put undefined so we know immediately if the context has been initialized incorrectly

const CartProvider = ({ children, config }) => {
  const [cart, setCart] = useState<CartService.Cart>();
  const [cartTotal, setCartTotal] = useState(0);
  const [showCartHeaderTooltip, setShowCartHeaderTooltip] = useState(false);
  const headerTimer = useRef<any>();

  const { thrift_bag_cart_upsell } = config.content;

  const { showDrawer } = useContext(DrawerContext);

  const syncCart = async () => {
    const newCart = await CartService.getCart();

    setCart(newCart);
  };

  /**
   * Show Header tooltip for a number of seconds
   */
  const showHeaderUpdate = () => {
    clearTimeout(headerTimer.current);
    setShowCartHeaderTooltip(true);
    headerTimer.current = setTimeout(() => {
      setShowCartHeaderTooltip(false);
    }, 5000);
  };

  const atMaxThriftBags = (): boolean => {
    const thriftBagTypesInCart = cart?.items?.filter((item) => thriftBagVariantIDs.includes(item.variant.id)) ?? [];
    let thriftBagCount = 0;
    
    for (let i = 0; i < thriftBagTypesInCart.length; i++) {
      thriftBagCount += thriftBagTypesInCart[i].quantity;
    }
    
    return thriftBagCount >= MAX_BAGS_PER_ORDER;
  }

  const addItem = async (lineItem: CheckoutLineItemInput, showTooltip?: boolean): Promise<void> => {
    //TODO handle error
    if (lineItem.variantId.indexOf('gid://') < 0) {
      lineItem.variantId = `gid://shopify/ProductVariant/${lineItem.variantId}`; // If the id isn't in the graphql format, let's add it
    }

    const isItemInCart = !!cart?.items?.find((itm) => itm.variant.id === lineItem.variantId);

    if(!isItemInCart) {
      await CartService.addItem(lineItem);
      await syncCart();
    }


    if (showTooltip) {
      showHeaderUpdate();
    } else {
      showDrawer(Cart as any, { alignment: 'right' });
    }
  };

  const addThriftBag = async (lineItem: CheckoutLineItemInput, showTooltip?: boolean): Promise<void | Error> => {

    //TODO handle error
    if (lineItem.variantId.indexOf('gid://') < 0) {
      lineItem.variantId = `gid://shopify/ProductVariant/${lineItem.variantId}`; // If the id isn't in the graphql format, let's add it
    }

    // where is the logic for checking if a bag type already exists?

    if (atMaxThriftBags()) return new Error(`Max ${MAX_BAGS_PER_ORDER} Thrift+ Bags per order`);

    await CartService.addItem(lineItem);
    await syncCart();

    if (showTooltip) {
      showHeaderUpdate();
    } else {
      showDrawer(Cart as any, { alignment: 'right' });
    }
 
  };

  const openCart = () => {
    Tracking.ViewCart(
      cart?.total?.amount || 0,
      cart?.items.map((itm) => ({
        item_id: itm.product_id,
        item_name: itm.title,
        item_brand: itm.tagsByCategory.Brand,
        price: itm.variant.price.amount,
      }))
    );
    showDrawer(Cart as any, { alignment: 'right' });
  };

  const updateItem = async (lineItem: CheckoutLineItemUpdateInput): Promise<void> => {
    console.time('tester');
    await CartService.updateItem(lineItem);
    await syncCart();
    console.timeEnd('tester');
  };

  const removeItem = async (lineItemId: string): Promise<void> => {
    const matchingLineItem = cart?.items.find((itm) => itm.id === lineItemId);
    if (matchingLineItem) {
      Tracking.RemoveFromCart({
        item_id: matchingLineItem.product_id,
        item_name: matchingLineItem.title,
        item_brand: matchingLineItem.tagsByCategory?.Brand,
        price: matchingLineItem.variant?.price?.amount,
      });
    }
    await CartService.removeItem(lineItemId);
    await syncCart();
  };

  const clearCart = useCallback(async () => {
    await Promise.all(
      cart?.items?.map(async (itm) => {
        await CartService.removeItem(itm.id);
      })
    );
    await syncCart();
  }, [cart]);

  useEffect(() => {
    syncCart();
  }, []);

  return (
    <CartContext.Provider
      value={{
        cart,
        addItem,
        addThriftBag,
        updateItem,
        removeItem,
        clearCart,
        openCart,
        atMaxThriftBags,
        showCartHeaderTooltip,
        showThriftBagUpsell: thrift_bag_cart_upsell,
        // cartTotal,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export { CartContext, CartProvider };