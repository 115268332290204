import { roundNumberDownToNearest5 } from "../../../../utils/helpers";
import { capitalizeFirstLetter } from "../../../../utils/helpers";
import pluralize from 'pluralize';
import { CanonicalItem } from '../../../../data-fetching/canonicalItem';
import { allowedMaterials } from "../../../../utils/formatting";

export const calculatePercentageDiscount = (price: number, strikethroughPrice: number): number => {
  if (!strikethroughPrice) return 0;

  return roundNumberDownToNearest5(Math.round(((strikethroughPrice - price) / strikethroughPrice) * 100));
}

export const formatProductStyle = (styleTag: string) => {
  return capitalizeFirstLetter(pluralize(styleTag));
};

export const formatProductBrand = (brandTag: string) => {
  return capitalizeFirstLetter(encodeURIComponent(brandTag));
};

export const normaliseShopifyIdToNumber = (shopifyId: string) => {
  // example format: gid://shopify/ProductVariant/43526758301886
  // this converts it to just the last part as a number
  return parseInt(shopifyId.split('/').slice(-1).join(''))
}

const generateFullMaterialDisplayString = (materials: CanonicalItem["materials"]): string => {

  const displayMaterial = materials
    .find(material => allowedMaterials.includes(material.toLowerCase()));
  const materialPrefix = displayMaterial && materials.length === 1 ? 'Pure' : undefined;
  const materialSuffix = displayMaterial && materials.length > 1 ? 'Blend' : undefined;

  const materialComponents = [
    materialPrefix,
    displayMaterial,
    materialSuffix,
  ].join(' ');

  return materialComponents;
}

export const isNotUndefined = <T>(anything: T | undefined): anything is T => {
  return typeof anything !== 'undefined';
}

export const toPDPtitle = (item: CanonicalItem): string => {
  const { brand_type, brand, style, materials } = item;
  const materialDisplayString = generateFullMaterialDisplayString(materials);

  const titleComponents = [
    brand_type === 'vintage' ? brand_type : '',
    brand,
    materialDisplayString,
    style,
  ].filter(isNotUndefined);

  return titleComponents
    .filter(titleComponent => titleComponent?.trim().length !== 0) // remove any empty segments
    .map(titleComponent => capitalizeFirstLetter(titleComponent))
    .join(' ');
};