import algoliasearch from 'algoliasearch/lite';
import { isNotNullOrUndefined } from '@apollographql/apollo-tools';
import { algoliaIndices, genderDepartmentTags } from '../../../utils/constants';
import { ProductSearchState, AlgoliaRefinements, AlgoliaFacet, SearchRefinements } from './types';
import { Hit, MultipleQueriesQuery, SearchResponse } from '@algolia/client-search';
import { toSearchableFacet } from './utils';
import { AlgoliaHit } from '../Hit/hit.types';
import { getUnixTimestampForDaysAgo } from '../../../utils/helpers';

const searchClient = algoliasearch(
  process.env.NEXT_PUBLIC_ALGOLIA_APPLICATION_ID as string,
  process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY as string
);

const toFilter = (prefix: string, values: undefined | string | number | boolean | string[]) => {
  if (typeof values === 'undefined') return null;
  if (typeof values === 'string' && values.trim() === '') return null;
  if (Array.isArray(values) && values.length === 0) return null;
  const arrayOfValues = Array.isArray(values) ? values : [`${values}`];
  const filterStr = arrayOfValues
    .map(value => {
      return `${prefix}:"${value.trim()}"`;
    })
    .join(" OR ");
  return `(${filterStr})`
}

export type AlgoliaSearchResults = {
  hits: Array<AlgoliaHit>,
  pagination: {hits: number, total_hits: number, total_pages: number, page: number},
  refinements: SearchRefinements
}

export const algoliaProductSearch = async (search: ProductSearchState) => {

  const fixedFilters = [
    toFilter("inventory_available", true),
    toFilter("collections", search.collections),
    toFilter("tags", search.department ? genderDepartmentTags[search.department].replace("'", "\\'") : undefined),
    ...(search.additional_filters ?? []),
    ...(search.collections?.includes('-new-in') ? [`named_tags.processed_at > ${getUnixTimestampForDaysAgo(30)}`] : []), // hacky workaround for new-in collection
    "price > 1"
  ].filter(isNotNullOrUndefined)

  const allSearchableFilters: [AlgoliaFacet, string | null][] = [
    [AlgoliaFacet.brand, toFilter("named_tags.brand", search.brand)],
    [AlgoliaFacet.brand_type, toFilter("named_tags.brand_type", search.brand_type)],
    [AlgoliaFacet.category, toFilter("named_tags.category", search.category)],
    [AlgoliaFacet.colour, toFilter("named_tags.colour", search.colour)],
    [AlgoliaFacet.condition, toFilter("named_tags.condition", search.condition)],
    [AlgoliaFacet.material, toFilter("named_tags.material", search.material)],
    [AlgoliaFacet.original_tags, toFilter("named_tags.original_tags", search.original_tags)],
    [AlgoliaFacet.size, toFilter("named_tags.size", search.size)],
    [AlgoliaFacet.style, toFilter("named_tags.style", search.style)],
    [AlgoliaFacet.price_range, toFilter("price_range", search.price_range)],
  ];

  const activeFilters = allSearchableFilters
    .filter(([facet, filter]) => isNotNullOrUndefined(filter));

  const allFilters = [...fixedFilters, ...activeFilters.map(([_facet, filter]) => filter)].join(" AND ");
  const mainQueryParams = {
    hitsPerPage: search.pagination.pageSize,
    page: search.pagination.page,
    filters: allFilters,
    facets: Object.values(AlgoliaFacet),
    clickAnalytics: true,
    userToken: search.personalisation?.authenticatedUserToken ?? search.personalisation?.userToken,
  };
  const indexName = search.sort ?? algoliaIndices.shopifyProducts;
  const response = await searchClient.search<AlgoliaHit>([
    {
      type: "default",
      indexName: indexName,
      query: search.query,
      params: mainQueryParams,
    },
    ...activeFilters.map(([facet, filter]): MultipleQueriesQuery => {
      const allOtherActiveFilters = activeFilters.filter(([otherFacet, _otherFilter]) => facet !== otherFacet);
      const filters = [...fixedFilters, ...allOtherActiveFilters.map(([_facet, filter]) => filter)].join(" AND ");

      const facetQueryParams = {
        hitsPerPage: 0,
        page: 0,
        filters: filters,
        facets: [facet],
        userToken: search.personalisation?.authenticatedUserToken ?? search.personalisation?.userToken,
      };
      return {
        type: 'default',
        indexName: indexName,
        query: search.query,
        params: facetQueryParams
      }
    })
    ])
  const results = response.results as SearchResponse<AlgoliaHit>[]
  const result = results[0];
  const hits: AlgoliaHit[] = result.hits.map((hit, index) => {
    return {
      ...hit,
      __queryID: result.queryID,
      __position: (search.pagination.page * search.pagination.pageSize) + index + 1, // first one is position 1, not 0
      objectID: hit.objectID,
    } as AlgoliaHit;
  });
  const baseRefinements = result.facets as AlgoliaRefinements;
  const refinements: AlgoliaRefinements = results.slice(1).reduce((refinementResult, facetResult) => {
    return {
      ...refinementResult,
      ...facetResult.facets
    }
  }, baseRefinements);

  const searchRefinements = Object.fromEntries(
    (Object.entries(refinements) as [keyof AlgoliaRefinements, AlgoliaRefinements[keyof AlgoliaRefinements]][])
      .map(([key, value]) => [toSearchableFacet(key), value])
  ) as SearchRefinements;

  const pagination = {
    total_hits: result.nbHits ?? 0,
    total_pages: result.nbPages ?? 0,
    page: result.page ?? 0,
    hits: result.hits.length ?? 0,
  }
  return {
    hits,
    refinements: searchRefinements,
    pagination
  }
}