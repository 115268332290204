import React, { useState } from 'react';
import { Button } from '../../core';
import { useSearchState } from '../queries/useSearchState';
import { useRefinements } from '../queries/useRefinements';
import { ProductSearchState, SearchableFacets, SearchRefinements } from '../queries/types';
import Icon from '../../core/Icons';
import { RefinementTogglesForFacet } from './RefinementTogglesForFacet';
import { ListFacets } from './ListFacets';
import { GroupedRefinementsForFacet } from './GroupedRefinementsForFacet';
import { groupSizeRefinements } from './sizeGroupings';
import { searchableFacetToDisplayString } from './utils';


type FacetSidebarProps = {
  onClose: VoidFunction;
}


export const FacetSidebar: React.FC<FacetSidebarProps> = ({ onClose }) => {
  const [searchState] = useSearchState();

  return (
    <section className="h-full flex flex-col max-h-full">
      {searchState ? <FacetManager searchState={searchState} /> : null }
      <div className={"mt-auto p-2 border-t-1 border-gray-300"}>
        <Button text="View items" fullWidth onClick={() => onClose()} />
      </div>
    </section>
  );
}

const FacetManager: React.FC<{ searchState: ProductSearchState}> = ({ searchState}) => {
  const result = useRefinements(searchState);
  const refinements: Partial<SearchRefinements> = (result?.data ?? {});
  const [activeFacet, setActiveFacet] = useState<SearchableFacets | null>();
  const resetFacet = activeFacet ? () => setActiveFacet(null) : undefined;
  const searchableFacetsWithResults = Object.values(SearchableFacets).filter(searchableFacet => Object.hasOwn(refinements, searchableFacet));

  return (<>
    <FacetHeader title={activeFacet ?? "Filter By"} onBack={resetFacet} />
    <ul style={{ marginBottom: '-1px', scrollbarWidth: 'thin' }} className={'overflow-auto'}>
      {!activeFacet ? <ListFacets onSelect={setActiveFacet} facets={searchableFacetsWithResults} searchState={searchState} /> : null}
      {activeFacet ? <FacetRefinements facet={activeFacet} options={refinements[activeFacet] ?? {}} searchState={searchState} /> : null}
    </ul>
  </>);
}

const FacetRefinements: React.FC<{ facet: SearchableFacets, options: Record<string, number>, searchState: ProductSearchState } > = ({ facet, options, searchState }) => {
  if (facet === SearchableFacets.size) {

    return <GroupedRefinementsForFacet facet={facet} options={options} searchState={searchState} groupRefinements={groupSizeRefinements} />
  } else {
    return <RefinementTogglesForFacet facet={facet} options={options} searchState={searchState} />
  }
}

const FacetHeader: React.FC<{ onBack?: VoidFunction, title: string}> = ({onBack, title }) => {
  return (
    <h1 className={"font-bold text-md py-4 px-2 border-b-1 border-gray-300 flex items-center"}>
      {onBack ?
        (<button onClick={onBack} className="mr-2">
          <Icon title="return" />
        </button>)
      : null}
      {searchableFacetToDisplayString(title)}
    </h1>
  )
}

