
import React from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import classNames from 'classnames';

import styles from './styles.module.css';
import { CheckIcon, ChevronDown } from '../../core/Icons/iconList';
import { ProductSearchState } from '../queries/types';
import { algoliaIndices } from '../../../utils/constants';

import { useSearchUpdater } from '../queries/useSearchUpdater';

const sortOptions = [
  {
    sort: algoliaIndices.shopifyProducts,
    title: "Newest",
    default: true,
  },
  {
    sort: algoliaIndices.shopifyProductsPriceAsc,
    title: "Price (low to high)",
    default: false,
  },
  {
    sort: algoliaIndices.shopifyProductsPriceDesc,
    title: "Price (high to low)",
    default: false,
  }
]

export const SortResultsDropdown: React.FC<{ searchState: ProductSearchState}> = ({ searchState }) => {
  const searchUpdater = useSearchUpdater();

  const appliedOption = sortOptions.find(option => option.sort === searchState.sort);
  const dropdownTitle = sortOptions.find(option => option.sort === searchState.sort)?.title ?? "Sort"

  const applyOption = (option: typeof sortOptions[0]) => {
    searchUpdater({
      ...searchState,
      sort: option.sort,
    })
  }

  return (
    <DropdownMenu.Root modal={false}>
      <DropdownMenu.Trigger className={classNames('body-12-bold-caps', styles.dropdownButton)}>
        {dropdownTitle}
        <ChevronDown width={14} />
      </DropdownMenu.Trigger>
      <DropdownMenu.Content className={styles.dropdownItems}>
        {sortOptions?.map((option) => {
          return (
            <DropdownMenu.Item
              className={classNames('body-12-bold-caps', styles.dropdownItem)}
              onSelect={() => applyOption(option)}
              key={option.sort}
            >
              <span>{option.title}</span>
              {(option === appliedOption || !appliedOption && option.default) && (
                <span>
                  <CheckIcon width={14} />
                </span>
              )}
            </DropdownMenu.Item>
          );
        })}
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};
