import React, {useContext} from 'react';
import { Icon } from '../../../core';
import { useItemWatchCount, getWishlistLength } from '../../../../data-fetching/useItemWatchCount';
import styles from './styles.module.css';
import { WishlistContext } from '../../../../context/WishlistContext';

interface Props {
    productId: string | number;
    onClick: React.MouseEventHandler<HTMLButtonElement>;
}

export const ItemWatchListToggleWithCount = ({ productId, onClick }: Props) => {
    const { wishlistShopifyData } = useContext(WishlistContext);

    const instancesOfThisProductInWishlist = getWishlistLength(productId, wishlistShopifyData);
    const watchCount = useItemWatchCount(productId);

    const colour = instancesOfThisProductInWishlist === 0 ? '#FFFCBF' : '#F2DDD6';

    return (
        watchCount && watchCount > 3 ? (
                <button onClick={onClick}>
                    <div className={styles.mainContainer} style={{backgroundColor: colour}}>
                            <span className="body-15 font-bold">
                                {watchCount}
                            </span>
                        <Icon title="heart" height={20} width={22} className={styles.bounce} />
                    </div>
                </button>
        ) : null
    );
};