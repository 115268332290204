import algoliasearch from 'algoliasearch/lite';
import { isNotNullOrUndefined } from '@apollographql/apollo-tools';
import { genderDepartmentTags } from '../../../utils/constants';
import { ProductSearchState } from './types';
import {  SearchResponse } from '@algolia/client-search';

const searchClient = algoliasearch(
  process.env.NEXT_PUBLIC_ALGOLIA_APPLICATION_ID as string,
  process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY as string
);

const toFilter = (prefix: string, values: undefined | string | number | boolean | string[]) => {
  if (typeof values === 'undefined') return null;
  if (typeof values === 'string' && values.trim() === '') return null;
  if (Array.isArray(values) && values.length === 0) return null;
  const arrayOfValues = Array.isArray(values) ? values : [`${values}`];
  const filterStr = arrayOfValues
    .map(value => {
      return `${prefix}:"${value.trim()}"`;
    })
    .join(" OR ");
  return `(${filterStr})`
}

type SearchSuggestionResponse = {
  query: string,
  shopify_products: {
    exact_nb_hits: number
  }
}

export const algoliaSearchSuggestions = async (search: ProductSearchState) => {

  const fixedFilters = [
    toFilter("inventory_available", true),
    toFilter("collections", search.collections),
    toFilter("tags", search.department ? genderDepartmentTags[search.department].replace("'", "\\'") : undefined),
  ].filter(isNotNullOrUndefined)

  const allFilters = fixedFilters.join(" AND ");
  const mainQueryParams = {
    hitsPerPage: 5,
    page: 0,
    // filters: allFilters,
  };

  const response = await searchClient.search<SearchSuggestionResponse>([
    {
      type: "default",
      indexName: "shopify_collections_query_suggestions",
      query: search.query,
      params: mainQueryParams
    },
    ], {})

  const results = response.results as SearchResponse<SearchSuggestionResponse>[];
  const result = results[0];
  const searchSuggestions = result.hits.map(hit => ({
    text: hit.query,
    hits: hit.shopify_products.exact_nb_hits,
  }));

  return {
    searchSuggestions
  }
}