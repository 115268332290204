import React, { useState } from 'react';
import { storyblokEditable } from '@storyblok/react';
import { Button, RichText } from '../../../core';
import { OrderBagPartnerStepStoryblok } from './types';
import styles from './styles.module.css';
import { useContext } from 'react';
import { CartContext } from '../../../../context/CartContext';
import SignpostLink from '../../../core/SignpostLink';
import { Tracking } from '../../../../utils/tracking';
import { useCookieConsent } from '../../../sections/CookieConsent/useCookieConsent';

interface Props {
  blok: OrderBagPartnerStepStoryblok;
}

const OrderBagPartnerStep = ({ blok }: Props) => {
  const { content, cta_text, variant_id, button_colour, gtag_custom_event } = blok || {};
  const { addThriftBag, openCart } = useContext(CartContext);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [cookieConsent] = useCookieConsent();

  async function handleClearOut() {
    setIsLoading(true);
    const result = await addThriftBag({variantId: variant_id, quantity: 1})            
    if (gtag_custom_event && cookieConsent?.ad_personalisation && cookieConsent?.ad_storage && cookieConsent?.ad_user_data) {
      // need marketing consent to do the tracking
      Tracking.PushToGTM({ event: gtag_custom_event });
    }
    if (result instanceof Error) {
      setError(result.message);
    }
    openCart();
    setIsLoading(false);
  }

  return (
    <div className=" w-full h-full relative p-10 flex flex-col items-center" {...storyblokEditable(blok)}>
      {content && <RichText className={styles.content} document={content} />}
      {cta_text && (
          <SignpostLink signpostText={cta_text} onClick={handleClearOut} bgColour={button_colour} iconRight={''} />
      )}
      {error && <div className={styles.error}>{error}</div>}
    </div>
  );
};

export default OrderBagPartnerStep;
