import { QueryClient } from '@tanstack/react-query';
import { persistQueryClient, PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'

import React, { PropsWithChildren } from 'react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'


export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: 0, // 5 minutes
    },
  },
});

const tryGetWindow = () => {
  try {
    return window;
  } catch (e) {
    console.log('disabled')
    return { localStorage: null };
  }
}

const persister = createSyncStoragePersister({
  storage: tryGetWindow().localStorage,
})

persistQueryClient({
  queryClient: queryClient,
  persister: persister,
  maxAge: Infinity,
})

export const ReactQueryProvider: React.FC<PropsWithChildren<{ hideDevTools?:boolean }>> = ({children, hideDevTools}) => {
  const showDevTools = !hideDevTools;
  return (
    <PersistQueryClientProvider client={queryClient} persistOptions={{ persister }}>
      {children}
      {showDevTools && <ReactQueryDevtools initialIsOpen={false} />}
    </PersistQueryClientProvider>
  )
}