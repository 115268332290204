import { truncate } from 'lodash';
import formatTitle from 'title';
import { Merge } from 'type-fest';
import { ProductService } from './product.service';
import { ShopifyService, GetCollectionListQueryVariables, GetCollectionListQuery } from './shopify.service';

export namespace CollectionService {
  export interface SingleCollection {
    title: string;
    description: string;
    id: string;
    seo: {
      title: string;
      description: string;
    };
    products: ProductService.List;
  }

  export async function getSingle(handle: string, productsAfter?: string): Promise<SingleCollection | null> {
    const { collectionByHandle } = await ShopifyService.getCollectionSingle({ handle, productsAfter });

    if (collectionByHandle) {
      const { title, description, products } = collectionByHandle!;

      let singleCollection: SingleCollection = {
        title: formatTitle(title),
        description,
        id: collectionByHandle.id.split('/').pop(),
        seo: {
          title: formatTitle(title),
          description: truncate(description, { length: 256 }),
        },
        products: ProductService.getListFromPaginatedProductPage(products),
      };

      return singleCollection;
    }

    return null;
  }

  export interface Collection {
    full_slug: string;
    handle: string;
    gender: string;
    cursor: string;
    id: string;
    title: string;
    description: string;
  }

  export interface CollectionList {
    collections: Merge<Collection, {}>[];
    pageInfo: GetCollectionListQuery['collections']['pageInfo'];
    endCursor: string;
  }

  export async function getList(variables?: GetCollectionListQueryVariables): Promise<CollectionList> {
    const {
      collections: { edges, pageInfo },
    } = await ShopifyService.getCollectionList(variables);

    const collections: CollectionList['collections'] = edges
      .filter(({ node }) => node.handle.includes('men-') || node.handle.includes('women-'))
      .map(({ node, cursor }) => {
        const { id, updatedAt, title, description } = node;
        const slugArr = node.handle.split('-');
        const gender = slugArr.shift();

        return {
          full_slug: `${gender}/${slugArr.join('-')}`,
          gender,
          handle: `${slugArr.join('-')}`,
          cursor,
          id: id,
          published_at: updatedAt,
          title: title,
          description: description,
          seo: {
            title: title,
            description: description,
          },
        };
      });

    let endCursor = edges[edges.length - 1].cursor;

    return { collections, pageInfo, endCursor };
  }
}
