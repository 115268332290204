import { useContext, useEffect, useState } from "react"
import { WishlistContext } from "../context/WishlistContext";
import { useQuery } from '@tanstack/react-query';
import { cleanId } from "../utils/shopifyUtils";

export const getWishlistLength = (shopifyProductId: string | number, wishlistShopifyData: any): number => {
    return wishlistShopifyData
        .filter(product => cleanId(product.id) === cleanId(shopifyProductId))
        .length;
};

export const useItemWatchCount = (shopifyProductId: string | number): number | null => {
    const { getItemWatchCount, wishlistShopifyData } = useContext(WishlistContext);
    const countInWishlist = getWishlistLength(shopifyProductId, wishlistShopifyData);

    const result = useQuery({
      queryKey: ['items', shopifyProductId, 'watch-count', countInWishlist],
      queryFn: (options) => {
        const id = options.queryKey[1];
        return getItemWatchCount(cleanId(id))
      },
      staleTime: 30 * 1000,
      gcTime: 30 * 1000,
    })

    return result.data ?? null
}