import React from 'react';
import { CartService } from '../../../modules/cart.service';
import { CartItem } from '../CartItem';
import styles from './styles.module.css';

interface Props {
  items: CartService.CartItem[];
}

export const CartList: React.FC<Props> = ({ items }) => {
  return (
    <div className={styles.list}>
      {items.map((item) => (
        <CartItem key={item.id} item={item} />
      ))}
    </div>
  );
};
