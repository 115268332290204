import { useQuery } from '@tanstack/react-query';
import { algoliaProductSearch } from './algoliaProductSearch';
import { queryClient } from '../../../context/QueryContext';
import { SessionContext } from '../../../context/SessionContext';
import { useContext } from 'react';
import { Department } from './types';
import { algoliaSearchSuggestions } from './algoliaSearchSuggestions';

const cacheTime = 0//  1000 * 60 * 5; // 5 minutes

type Props = {
  query: string,
}

export const useSearchSuggestions = (props: Props) => {
  const { isWomen } = useContext(SessionContext)
  return useQuery({
    queryKey: ['search-options', props.query],
    queryFn: (params) =>{
      const search = params.queryKey[1];
      if (search.trim()  === '') return null;
      return algoliaSearchSuggestions({
        query: search,
        department: isWomen ? Department.Womens : Department.Mens,
        pagination: {
          pageSize: 0,
          page: 0,
        }
      }).then(result => result.searchSuggestions)
    },
    staleTime: cacheTime, // 5 minutes
    gcTime: cacheTime, // 5 minutes
  },
    queryClient
  );
};
