import React, { useState } from 'react';
import { storyblokEditable } from '@storyblok/react';
import { Button, RichText } from '../../../core';
import { GetStartedPartnerStepStoryblok } from './types';
import styles from './styles.module.css';
import { thriftBagVariantID } from '../../../../utils/constants';

import router from 'next/router';
import { useContext } from 'react';
import { CartContext } from '../../../../context/CartContext';
import SignpostLink from '../../../core/SignpostLink';
import Tooltip from '../../../core/Button/Tooltip';
import { MAX_BAGS_PER_ORDER } from '../../../../consts/bags';

interface Props {
  blok: GetStartedPartnerStepStoryblok;
  next: () => void;
  isLastSlide: boolean;
  variantID: string;
}

const GetStartedPartnerStep = ({ blok, next, isLastSlide, variantID }: Props) => {
  const { content, cta_text, button_colour } = blok || {};
  const { cart, clearCart, addThriftBag } = useContext(CartContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  
  async function handleClearOut() {
    setIsLoading(true);
    // await clearCart(); // TODO find out why this was added?
    const result = await addThriftBag({variantId: variantID, quantity: 1})
    if (result instanceof Error) {
      setError(result.message);
    }
    setIsLoading(false);
    // router.push(cart?.url); //TODO Is this necessary? Seems buggy
  }

  return (
    <div className=" w-full h-full relative p-10 flex flex-col items-center" {...storyblokEditable(blok)}>
      {content && <RichText className={styles.content} document={content} />}
      {cta_text && (
        <>
          {isLastSlide ? (
            <>
            <Button
              text={cta_text}
              onClick={handleClearOut}
              isLoading={isLoading}
              loadingText={'Adding'}
              isSecondary={true}
              bgColour={button_colour}
            />
            <Tooltip open={showTooltip} text={`Max ${MAX_BAGS_PER_ORDER}`} />
            </>
          ) : (
            <SignpostLink signpostText={cta_text} onClick={next} isSecondary={true} bgColour={button_colour} />
          )}
        </>
      )}
      {error && <div className={styles.error}>{error}</div>}
    </div>
  );
};

export default GetStartedPartnerStep;
