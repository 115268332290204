import { ProductService } from '../modules/product.service';

export type ProductMini = {
  title: string;
  id: string;
  handle: string;
  brand?: string;
  images: ProductService.Single['images'];
  variants: ProductService.Single['variants'];
  totalInventory?: number;
  tagsByCategory: {
    SizeFilter?: string;
    ConditionWebsite?: string;
    Colour?: string;
    Material?: string;
    Care?: string;
    Style?: string;
    Brand?: string;
    SKU?: string;
    size?: string;
    dimensions?: {width: number, height: number, length: number}
  }; //TODO: add in the diff
  lastSync?: string;
};

// First Block
export function setRecentlyViewedItem(
  { title, images, variants, handle, id, tagsByCategory, totalInventory }: ProductService.Single,
  count = 12 // Set the number how many products you want to capture
) {
  const pdpData: ProductMini = {
    title: title,
    id: id,
    handle,
    brand: tagsByCategory.brand,
    images: images,
    variants,
    totalInventory,
    tagsByCategory,
    lastSync: new Date().toISOString(),
  };
  // Init Empty Array to push data
  const productArr = [];
  // Create a couple of variables
  let jsonResp: void[], jsonRespArr: any, jsonRespArrStr: string;

  // Now push the pdpData into Array so that we can use it
  productArr.push(pdpData);
  // Get the product title from pdpData
  const currPdpTitle = pdpData.title;
  // Now Convert current page data into Strings which we already pushed in Array
  const pdpDataString = JSON.stringify(productArr);
  // Set the variable for localStorage
  const localData = localStorage.getItem('recently_viewed');

  // Second Block
  // first we need to check data if data is not there then store right // away
  if (localData == null) {
    localStorage.setItem('recently_viewed', pdpDataString);
  } else if (localData != null) {
    // If data is there then we need to check couple of other conditions
    // Create Variable for oldData or Previous page
    const oldPdpData = localStorage.getItem('recently_viewedPDP') || localData;

    // Count the amount of data stored in strings so that we can remove // old products from the stack
    const countPdpData = (oldPdpData.match(/handle/g) || []).length;
    // we also need to check if user is not visiting page again
    const reVisitPdp = oldPdpData ? oldPdpData.includes(currPdpTitle) : false;

    // Get old data, merged it with new data and store merged data
    if (countPdpData < count && reVisitPdp == false) {
      jsonResp = JSON.parse(oldPdpData) || [];
      jsonRespArr = jsonResp.concat(productArr) || [];
      jsonRespArrStr = JSON.stringify(jsonRespArr.filter((prod) => prod?.totalInventory > 0));
      localStorage.setItem('recently_viewedPDP', jsonRespArrStr);
    }
    // If User visited more the required count then delete first item
    else if (countPdpData >= count && reVisitPdp == false) {
      jsonResp = JSON.parse(oldPdpData);
      const slicedJsonResp = jsonResp.slice(1);
      jsonRespArr = slicedJsonResp.concat(productArr);
      jsonRespArr = JSON.stringify(jsonRespArr.filter((prod: ProductMini) => prod?.totalInventory > 0));
      localStorage.setItem('recently_viewedPDP', jsonRespArr);
    }
  }
}

export async function getRecentPdp(count = 12): Promise<ProductMini[] | null> {
  // First let's convert localStorage data into object again
  const pdpData = JSON.parse(localStorage.getItem('recently_viewedPDP')) || null;

  if (pdpData == null) {
    return null;
  }
  // Create a empty Array
  const recentViewedHtml = [];

  // Loop through all the data and inject into HTML using ES6
  for (let i = 0; i < pdpData.length; i++) {
    if (recentViewedHtml.length >= count) break;
    let item = pdpData[i];
    let itemSync = new Date(item.lastSync);
    let thirtyMinsAgo = new Date();
    thirtyMinsAgo.setMinutes(thirtyMinsAgo.getMinutes() - 1);

    if (false && (itemSync < thirtyMinsAgo || !itemSync.getTime())) {
      const handle = item.handle;
      const shopifyProduct = await ProductService.getSingle(handle);
      if (!shopifyProduct?.totalInventory) {
        continue;
      }
    }

    if (item.id) {
      recentViewedHtml.push(item);
    }
  }

  localStorage.setItem('recently_viewedPDP', JSON.stringify(recentViewedHtml)); //sync local storage to match recently viewed list with sold out items removed
  return recentViewedHtml;
}
