import nookies from 'nookies';
import formatTitle from 'title';
import { NextPageContext } from 'next';
import { ShopifyService, CheckoutLineItemInput, CheckoutLineItemUpdateInput, CurrencyCode } from './shopify.service';
import { isNotNullOrUndefined } from '@apollographql/apollo-tools';

const CHECKOUT_ID = 'CHECKOUT_ID';

export namespace CartService {
  export interface CartItem {
    id: string;
    title: string;
    quantity: number;
    product_id: string;
    tagsByCategory: {
      SizeFilter?: string;
      ConditionWebsite?: string;
      Colour?: string;
      Material?: string;
      Care?: string;
      Style?: string;
      Brand?: string;
      size?: string;
      [type: string]: string;
    };
    collections: string[];
    variant: {
      id: string;
      title: string;
      url: string;
      price: {
        amount: number;
        currencyCode: CurrencyCode;
      };
      compareAtPrice: {
        amount: number;
        currencyCode: CurrencyCode;
      };
      image: {
        src: string;
        alt: string;
      };
      availableForSale: boolean;
    };
    timeAddedToCart?: Date;
  }

  export type Cart = {
    items: CartItem[];
    subtotal: {
      amount: number;
      currencyCode: CurrencyCode;
    };
    tax: {
      amount: number;
      currencyCode: CurrencyCode;
    };
    total: {
      amount: number;
      currencyCode: CurrencyCode;
    };
    url: string;
  };

  export async function getCart(context?: NextPageContext): Promise<Cart | undefined> {
    //@ts-ignore
    const checkoutId = nookies.get(context, CHECKOUT_ID).CHECKOUT_ID;

    if (checkoutId) {
      const resp = await ShopifyService.getCart({ checkoutId });

      if (resp.node?.__typename === 'Checkout' && resp?.node?.completedAt) {
        // Checkout has been completed, so let's delete it
        document.cookie = 'CHECKOUT_ID=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        return;
      }

      if (resp.node?.__typename === 'Checkout') {
        const removedListings: string[] = [];
        const items: CartItem[] = resp.node.lineItems?.edges?.map(({ node }) => {
          const tagsByCategory = {};
          if (node.variant === null) {
            removedListings.push(node.id);
            return null;
          }

          node.variant?.product?.tags?.forEach((tag) => {
            const tagArr = tag.split('_');
            if (tagArr[0].indexOf(':') < 0 && tagArr[1]) {
              tagsByCategory[tagArr[0]] = tagArr[1];
            }
          });

          const item: CartItem = {
            id: node.id,
            tagsByCategory,
            product_id: node.variant?.product?.id.split('/').pop(),
            title: formatTitle(node.title),
            quantity: node.quantity,
            collections: node.variant?.product?.collections?.edges?.map(edge => edge.node.handle) ?? [],
            variant: {
              id: node.variant?.id!,
              title: node.variant?.title!,
              url: `/products/${node.variant?.product?.handle}`,
              price: {
                amount: Number(node.variant?.priceV2?.amount),
                currencyCode: node.variant?.priceV2?.currencyCode!,
              },
              compareAtPrice: {
                amount: Number(node.variant?.compareAtPriceV2?.amount),
                currencyCode: node.variant?.compareAtPriceV2?.currencyCode!,
              },
              image: {
                src: node.variant?.image?.transformedSrc!,
                alt: node.variant?.image?.altText || '',
              },
              availableForSale: node.variant?.availableForSale,
            },
          };

          return item;
        }).filter(isNotNullOrUndefined);

        if (removedListings.length) {
          await Promise.all(removedListings.map(id => removeItem(id)));
        }

        return {
          items,
          url: resp.node.webUrl,
          subtotal: {
            amount: Number(resp.node.lineItemsSubtotalPrice.amount),
            currencyCode: resp.node.lineItemsSubtotalPrice.currencyCode,
          },
          tax: {
            amount: Number(resp.node.totalTaxV2.amount),
            currencyCode: resp.node.totalTaxV2.currencyCode,
          },
          total: {
            amount: Number(resp.node.totalPriceV2.amount),
            currencyCode: resp.node.totalPriceV2.currencyCode,
          },
        };
      } else {
        // Assume something is wrong with the cart (like checkout is complete) and delete cookie
        document.cookie = 'CHECKOUT_ID=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      }
    }
  }

  export async function getItemCount(context?: NextPageContext): Promise<number> {
    let count: number = 0;
    //@ts-ignore
    //TODO: check why failing
    //TODO: review nookie vs react-cookies
    const checkoutId = nookies.get(context, CHECKOUT_ID).CHECKOUT_ID;

    if (checkoutId) {
      const { node } = await ShopifyService.getCartItemCount({ checkoutId });

      if (node?.__typename === 'Checkout') {
        node?.lineItems.edges?.forEach((lineItem) => {
          count += lineItem.node.quantity;
        });
      }
    }

    return count;
  }

  export async function addItem(lineItem: CheckoutLineItemInput, context?: NextPageContext): Promise<void> {
    const checkoutId = nookies.get(context).CHECKOUT_ID;
    // we wanna be addressing timeAddedToCart here?
    try {
      if (!checkoutId) {
        const { checkoutCreate } = await ShopifyService.createCart({ input: { lineItems: [lineItem] } });
        nookies.set(context, CHECKOUT_ID, checkoutCreate?.checkout?.id!, { maxAge: 30 * 24 * 60 * 60, path: '/' });
      } else {
        const resp = await ShopifyService.addCartItem({ checkoutId, lineItem });
      }
    } catch (error) {
      const { checkoutCreate } = await ShopifyService.createCart({ input: { lineItems: [lineItem] } });

      nookies.set(context, CHECKOUT_ID, checkoutCreate?.checkout?.id!, { maxAge: 30 * 24 * 60 * 60, path: '/' });
    }
  }

  export async function updateItem(lineItem: CheckoutLineItemUpdateInput, context?: NextPageContext): Promise<void> {
    //@ts-ignore
    const checkoutId = nookies.get(context, CHECKOUT_ID).CHECKOUT_ID;
    await ShopifyService.updateCartItem({ checkoutId, lineItem });
  }

  export async function removeItem(lineItemId: string, context?: NextPageContext): Promise<void> {
    try {
      //@ts-ignore
      const checkoutId = nookies.get(context, CHECKOUT_ID).CHECKOUT_ID;

      await ShopifyService.removeCartItem({ checkoutId, lineItemId });
    } catch (error) {
      console.error('Error removing item:', error);
      throw error;
    }
  }

  export interface RemoveItem {
    id: string;
    variant: {
      id: string;
    };
  }

  export type CartDetail = {
    url: string;
  };

  export async function removeAllItems(context?: NextPageContext): Promise<CartDetail | void> {
    //@ts-ignore
    const checkoutId = nookies.get(context, CHECKOUT_ID).CHECKOUT_ID;

    if (checkoutId) {
      const { node } = await ShopifyService.getCart({ checkoutId });

      if (node?.__typename === 'Checkout') {
        for (let index = 0; index < node.lineItems.edges.length; index++) {
          await CartService.removeItem(node.lineItems.edges[index].node.variant?.id);
        }
        return { url: node.webUrl };
      }
      return { url: '' };
    }
    return { url: '' };
  }
}
