import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import Link from 'next/link';

import styles from './styles.module.css';

import { Image, LoadingAnimation } from '../../../../../core';
import { useItemWatchCount } from '../../../../../../data-fetching/useItemWatchCount';
import { ListProps } from './types';
import { CanonicalItem } from '../../../../../../data-fetching/canonicalItem';
import { useShopifyItem } from '../../../../../../data-fetching/useShopifyItem';
import { WatchCount } from '../WatchCount';
import { RemoveFromWishlistButton } from '../RemoveFromWishlistButton';
import { MoveToCartButton } from '../MoveToCartButton';
import { PriceRenderer } from '../../../../../shopify/SimpleItemCard';
import { toShortTitle } from '../../../../../../utils/formatting';
import { useItemCallouts } from '../../../../../searching/useItemCallouts';

export const IndependentWishlistItemCard: React.FC<{handle: string, className?: string}> = ({handle, className}) => {
  const { item } = useShopifyItem(handle);
  if (!item) return null;
  return <WishlistItemCard item={item} className={className} />
}
const WishlistItemCard: React.FC<{item: CanonicalItem, className?: string}> = ({item, className}) => {
  const watchCount = useItemWatchCount(item.id);
  const isAvailable = item.in_stock;
  const imgSrc = item.photos[0];
  const callouts = useItemCallouts(item);
  const leftCallout = callouts.find(callout => callout.sidePreference === 'left');
  const rightCallout = callouts.find(callout => callout.sidePreference === 'right');


  return (
    <div className = {classNames("mb-3 relative", className)}>
      <Link
        href={`/products/${item.handle}`}
        className="forced-aspect-square border border-brandGrey relative block"
      >
        <Image src={imgSrc} alt="wishlist product" width="300" height="300" />
        <div className="flex items-end justify-end">
          <WatchCount watchCount={watchCount} />
        </div>
        {!isAvailable && <div className={classNames('display-18-caps', styles.soldOut)}>Sold out</div>}
      </Link>

      <Link className="my-2 block" href={`/products/${item.handle}`}>
        <div>{toShortTitle(item)}</div>
        <div>{item.size.display}</div>
      </Link>

      {item.price && (
        <p className="body-16-bold pb-3">
          <PriceRenderer item={item} />
        </p>
      )}

      <div className="flex gap-4">
        <MoveToCartButton item={item} />
        <RemoveFromWishlistButton item={item} />
      </div>
      {leftCallout && (
        <div className={`top-0 absolute py-1 px-2 text-xs font-bold uppercase ${leftCallout.colour === 'blackAlt' ? 'text-white' : 'text-blackAlt'} bg-${leftCallout.colour} ${rightCallout ? "left" : "right"}-0`}>
          {leftCallout.label}
        </div>
      )}
      {rightCallout && (
        <div className={`top-0 absolute py-1 px-2 text-xs font-bold uppercase ${rightCallout.colour === 'blackAlt' ? 'text-white' : 'text-blackAlt'} bg-${rightCallout.colour} right-0`}>
          {rightCallout.label}
        </div>
      )}
    </div>
  );
}

const List: React.FC<ListProps> = ({ list, loading }) => {
  const listRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollTo(0, 0);
    }
  }, [list]);

  return (
    <div className={classNames(styles.container)} ref={listRef}>
      {loading && (
        <div className="m-auto h-60 flex flex-col justify-center items-center">
          <LoadingAnimation width="w-16" />
        </div>
      )}
      <ol className={styles.list}>
        {list?.map((prod) => {
          return <IndependentWishlistItemCard key={prod.handle} handle={prod.handle} />;
        })}
      </ol>
    </div>
  );
};

export default List;
