import React, { useContext } from 'react';

import styles from './styles.module.css';

import DrawerHeader from '../DrawerHeader/index';

import { DrawerContext } from '../../../../../context/DrawerContext';

import { CartContext } from '../../../../../context/CartContext';
import { thriftBagVariantIDs } from '../../../../../utils/constants';
import { Cart as CartContent } from '../../../../sections/Cart/Cart';

const Cart = (props) => {
  const { hideDrawer } = useContext(DrawerContext);

  const handleBack = () => hideDrawer();

  const { cart, addItem } = useContext(CartContext);

  const cartCount = cart?.items?.length || 0;

  return (
    <div className={styles.drawerWrapper}>
      <div className={styles.drawerHeader}>
        <DrawerHeader title="Bag" subtitle={`${cartCount} items`} onBack={handleBack} />
      </div>

      <CartContent cart={cart} addItem={addItem} />
    </div>
  );
};

export default Cart;
