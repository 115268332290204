import { ProductSearchState } from './types';
import { useInfiniteQuery } from '@tanstack/react-query';
import { algoliaProductSearch, AlgoliaSearchResults } from './algoliaProductSearch';
import { queryClient } from '../../../context/QueryContext';

const cacheTime =  1000 * 60 * 5; // 5 minutes

export const useInfiniteSearch = (searchState: ProductSearchState, initialData?: AlgoliaSearchResults) => {
  const { pagination, ...restOfSearchState} = searchState;
  const formattedInitialData = initialData ? {
    pages: [initialData],
    pageParams: [pagination.page],
  } : undefined;
  return useInfiniteQuery({
    queryKey: ['search', restOfSearchState],
    queryFn: (params) =>{
      const search = params.queryKey[1] as ProductSearchState;
      return algoliaProductSearch({
        ...search,
        pagination: {
          pageSize: 50,
          page: params.pageParam,
        }
      })
    },
    initialData: formattedInitialData,
    initialPageParam: pagination.page ?? 0,
    getNextPageParam: (lastPage, pages) => {
      const nextPage = lastPage.pagination.page + 1;
      if (lastPage.pagination.total_pages > nextPage) return nextPage;
      return undefined;
    },
    getPreviousPageParam: (lastPage, pages) => {
      const prevPage = lastPage.pagination.page - 1;
      if (prevPage >= 0) return prevPage;
      return undefined;
    },
    staleTime: cacheTime, // 5 minutes
    gcTime: cacheTime, // 5 minutes,
  },
    queryClient
  );
};
