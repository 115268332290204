import React, { useContext } from 'react';
import { CanonicalItem } from '../../../../data-fetching/canonicalItem';
import { CartContext } from '../../../../context/CartContext';
import { Button } from '../../../core';
import { normaliseShopifyIdToNumber } from '../utils/helpers';

interface Props {
    item: CanonicalItem;
    isLoading: boolean;
    handleAddToCart: (variantId: string | number) => void;
}

export const ToggleCartItemButton: React.FC<Props> = ({ item, isLoading, handleAddToCart }) => {
    const { cart } = useContext(CartContext);

    const isItemInBasket = !!cart?.items?.find((itm) => normaliseShopifyIdToNumber(itm.variant.id) === item?.variant_id);
    const isOutOfStock = !item?.in_stock;
    const disableAddToCart = isItemInBasket || isLoading || isOutOfStock;

    return (
        <Button
            className="justify-center grow"
            isLoading={isLoading}
            loadingText="Adding to bag"
            text={isItemInBasket ? 'Added to bag' : isOutOfStock ? 'Sold out' : 'Add to bag'}
            disabled={disableAddToCart}
            onClick={async (e: any) => {
                e.preventDefault();
                handleAddToCart(item.variant_id);
            }}
        />
    );
};