export enum Department {
  Womens = 'women',
  Mens = 'men',
}

type Pagination = {
  pageSize: number,
  page: number,
}


export enum AlgoliaFacet {
  brand = 'named_tags.brand',
  brand_type = 'named_tags.brand_type',
  category = 'named_tags.category',
  colour = 'named_tags.colour',
  condition = 'named_tags.condition',
  material = 'named_tags.material',
  original_tags = 'named_tags.original_tags',
  size = 'named_tags.size',
  style = 'named_tags.style',
  price_range = 'price_range',
}

// reordering these will reorder them in the search sidebar
export enum SearchableFacets {
  size = 'size',
  category = 'category',
  style = 'style',
  brand = 'brand',
  brand_type = 'brand_type',
  colour = 'colour',
  condition = 'condition',
  material = 'material',
  price_range = 'price_range',
  original_tags = 'original_tags',
}


export type AlgoliaPersonalisation = {
  userToken?: string,
  authenticatedUserToken?: string,
}
export type AlgoliaRefinements = Record<AlgoliaFacet, Record<string, number>>
export type SearchRefinements = Record<SearchableFacets, Record<string, number>>

export type ProductSearchState = {
  pagination: Pagination,
  personalisation?: AlgoliaPersonalisation,
  query?: string,
  department?: Department,
  sort?: string,
  collections?: string | string[],
  tags?: string | string[],
  brand?: string | string[],
  brand_type?: string | string[],
  category?: string | string[],
  colour?: string | string[],
  condition?: string | string[],
  material?: string | string[],
  original_tags?: string | string[],
  size?: string | string[],
  style?: string | string[],
  price_range?: string | string[],
  additional_filters?: string[],
}
