import React, { useState, useContext } from 'react';
import truncate from 'lodash/truncate';

import { IntlUtility } from '../../../helpers';
import { CartService } from '../../../modules/cart.service';
import { Image, Icon } from '../../core';
import styles from './styles.module.css';
import { CartContext } from '../../../context/CartContext';
import classNames from 'classnames';
import CustomSelect from '../../core/CustomSelect';
import { thriftBagVariantIDs } from '../../../utils/constants';
import Link from 'next/link';
import { WishlistContext } from '../../../context/WishlistContext';
import formatTitle from 'title';
import { roundNumberDownToNearest5, capitalizeFirstLetter } from '../../../utils/helpers';
import { uppercaseSizeAttributes, hiddenSizeAttributes } from '../../../utils/constants';
import { MAX_BAGS_PER_ORDER } from '../../../consts/bags';
import { Help } from '../../account/Payout/Help';
import CartItem = CartService.CartItem;
import { useStoryblokConfig } from '../../../context/ConfigContext';

interface Props {
  item: CartService.CartItem;
  visible?: boolean;
  updateCart?: () => void;
}

interface State {
  quantity: number;
}

export const formatSizeForCart = (item: CartItem): string | null => {
    const { size, SizeShoeUK } = item.tagsByCategory;

    if (!size) return null;

    let formattedSize: string = '';

    for (const prefix of uppercaseSizeAttributes) {
      if (size.startsWith(prefix)) {
        formattedSize = `${prefix.toUpperCase()} ${size.split(':')[1]}`;
        return `Size ${formattedSize}`;
      }
    }

    if (size.startsWith('sml:')) {
      formattedSize = size.split(':')[1].toUpperCase();
    } else if (size.startsWith('sml')) {
      formattedSize = size.substring(3).trim().toUpperCase();
    } else if (size.includes('shoe')) {
      formattedSize = SizeShoeUK ?? size.substring(4).trim().toUpperCase();
    } else if (size.includes('waist')) {
      formattedSize = capitalizeFirstLetter(size.replace(':', ' '));
    }

    return `Size ${formattedSize}`;
  };

export const CartItem: React.FC<Props> = ({ item }) => {
  const { removeItem, updateItem } = useContext(CartContext);
  const { addItemToWishlist, itemIsInWishlist } = useContext(WishlistContext); //?

  const [isLoading, setIsLoading] = useState(false);

  const handleUpdateQty = async (qty) => {
    setIsLoading(true);
    // setState({ quantity: qty }); //TODO: do we need this qty here?
    if (qty === 0) {
      await removeItem(item.id);
    } else {
      if (isThriftBag) {
        const cappedQty = qty > MAX_BAGS_PER_ORDER ? MAX_BAGS_PER_ORDER : qty;
        await updateItem({ id: item.id, quantity: cappedQty });
      } else {
        await updateItem({ id: item.id, quantity: qty });
      }
    }
    setIsLoading(false);
  };

  const options = new Array(MAX_BAGS_PER_ORDER)
      .fill(null)
      .map((_, i) => ({ label: `${i+1}`, value: i+1 }));


  const isThriftBag = thriftBagVariantIDs.includes(item.variant.id);

  const isAvailable = item.variant.availableForSale;

  const discount = roundNumberDownToNearest5(Math.round(((item.variant.compareAtPrice.amount - item.variant.price.amount) / item.variant.compareAtPrice.amount) * 100));

  const formatProductDescriptionForCart = (item: CartService.CartItem): string => {
    const { Brand, Style, Channel, BrandType } = item.tagsByCategory;

    if (Channel === 'ThriftBox') {
      return 'Thrift+ Bag';
    }

    const isVintage = BrandType.toLowerCase() === 'vintage';
    const isUnbranded = Brand?.toLowerCase() === 'unbranded';
    const isUnknownBrand = Brand?.toLowerCase() === 'unknown';

    const title = [
      isVintage ? 'Vintage' : '',
      isUnbranded || isUnknownBrand ? '': Brand,
      Style ? Style : '',
    ].filter(element => element).join(' ');

    return title;
  };

  const config = useStoryblokConfig();
  const currentPromo = (config?.promo_tags ?? [])
    .map(storyblokPromo => ({
      collection: `${storyblokPromo.department}-${storyblokPromo.collection_name.trim().replace(/\s/g, "-").toLowerCase()}`,
      label: storyblokPromo.label_text,
    }))
    .find(promo => item.collections.includes(promo.collection))?.label;

  return (
    <div key={item.id} className={classNames(styles.list, { [styles.listLoading]: isLoading })}>
      <div className={styles.imageCont}>
        <Link href={item.variant.url} className="forced-aspect-square block">
          <Image src={item.variant.image.src} alt={item.variant.image.alt} className={styles.image} />
        </Link>
        {!isAvailable && <div className={classNames('display-10-caps', styles.soldOut)}>Sold out</div>}
      </div>

      <div className={styles.itemCont}>
        <Link href={item.variant.url}>
          <p className="body-14 block md:hidden">{formatTitle(formatProductDescriptionForCart(item))}</p>
          <p className="body-14 hidden md:block">{formatTitle(formatProductDescriptionForCart(item))}</p>
        </Link>
        <p className="body-14">{formatSizeForCart(item)}</p>

        <p className="body-14-bold whitespace-pre-line">
          {IntlUtility.formatPrice({
            amount: item.variant.price.amount * item.quantity,
            currencyCode: item.variant.price.currencyCode,
          })}
          {!!item.variant.compareAtPrice.amount && (
            <span className={'line-through pl-1 text-black50'}>
              {IntlUtility.formatPriceSimple(item.variant.compareAtPrice.amount)}
            </span>
          )}
        </p>
        {currentPromo ? (
          <span className={'body-14-bold text-brandRed font-family: italic'}>{currentPromo}</span>
        ) : null}
        {!currentPromo && discount >= 50 && !isThriftBag && (
          <span className={'body-14-bold text-brandRed font-family: italic'}>Last chance</span>
        )}
      </div>


        <div className={styles.btnCol}>
          {isThriftBag && (
            <label className="body-12 mb-2">
              <span className={"flex items-center gap-1 my-1"}>Qty <Help maxWidth={125}>You can fit 15-20 items in one bag.</Help></span>
              <CustomSelect
                options={options}
                value={item.quantity}
                onChange={({ value }) => handleUpdateQty(value)}
                fullWidth={false}
              />
            </label>
          )}
            <button className="body-12 mb-1 text-right whitespace-nowrap" onClick={() => handleUpdateQty(0)} >
              Remove
            </button>
            {isAvailable && !itemIsInWishlist(item) && (
              <button className="body-12 underline mb-1 text-right whitespace-nowrap" onClick={async () => {
                await addItemToWishlist(item).then(() => {
                  handleUpdateQty(0);
                });
              }}>
                Save for later
              </button>
            )}
        </div>
      </div>
  );
};