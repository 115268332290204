import React from 'react';
import { calculatePercentageDiscount } from '../utils/helpers';
import { IntlUtility } from '../../../../helpers';

interface Props {
    price: number;
    strikethrough_price?: number;
}

export const ItemPrice: React.FC<Props> = ({ price, strikethrough_price }) => {
    const discount = calculatePercentageDiscount(price, strikethrough_price || 0);

    return (
        <>
            {discount >= 75 && strikethrough_price ? (
                <>
                    <p className="body-16-bold">
                        {' '}
                        {IntlUtility.formatPrice({amount: price})}{' '}
                        {strikethrough_price > 0 && (
                        <span className="line-through pl-1 text-black50">
                            {IntlUtility.formatPrice({amount: strikethrough_price})}
                        </span>
                        )}
                    </p>
                    <div className="flex items-center justify-between">
                        {discount >= 75 && (
                            <div className="body-14-bold text-brandRed font-style: italic">{discount}% off</div>
                        )}
                    </div>
                </>
            ) : (
                <div className="flex items-center justify-between">
                    <p className="body-16-bold">
                        {' '}
                        {IntlUtility.formatPrice({amount: price})}{' '}
                        {strikethrough_price && (
                            <span className="line-through pl-1 text-black50">
                                {IntlUtility.formatPrice({amount: strikethrough_price})}
                            </span>
                        )}
                    </p>
                </div>
            )}
        </>
    )
}